.container {
	padding: 0 calc(8rem + 10px) 2rem;
}

.banner {
	border-radius: 24px;
	width: 100%;
	height: 85%;
}

.pricing-box {
	border-radius: 24px;
	background-color: var(--background_primary);
	box-shadow: var(--fontColor-secondary) 0px 2px 10px;
	height: auto;
}

.price {
	margin-bottom: 30px;
}

.price span {
	font-size: 1.8rem;
	font-weight: bold;
	color: #56ccf2;
}

.features {
	padding: 0;
	margin: 0;
	list-style: circle;
	padding: 0.5rem;
}

.subscribe {
	margin: 2rem 0;
	border-radius: 10px;
	width: fit-content;
	font-size: 1rem;
	padding: 0.5rem 2.25rem;
}

.details {
	background-color: var(--background_primary);
	box-shadow: 0px 0px 3px var(--fontColor-secondary);
	padding: 1rem;
	border-radius: 24px;
	display: flex;
	list-style: none;
	margin: 0;
}

.details li {
	flex: 1;
}

.details li p:first-child {
	margin-bottom: 0.25rem;
	color: var(--fontColor-secondary);
}

.videoIcon {
	width: 60px;
	cursor: pointer;
	filter: invert(1);
	position: absolute;
	top: 40%;
	left: 45%;
}

.video {
	aspect-ratio: 11/5;
	margin-bottom: 50px;
}

.crossIcons {
	width: 50px;
	cursor: pointer;
	position: absolute;
	right: 0%;
	top: 2px;
}

.details li p:last-child {
	margin-bottom: 0;
	font-weight: bold;
}

.clubContent {
	padding: 0;
	margin-left: -20px;
	padding-top: 1.2rem;
	position: sticky;
	top: 0;
	z-index: 9999;
	background-color: var(--background_primary);
	height: 70px;
}

.clubContent ul {
	display: flex !important;
}

.clubContent ul li {
	list-style: none;
	margin-right: 28px;
	color: var(--fontColor-primary);
	border-bottom: 2px solid var(--border);
	font-weight: bold;
	cursor: pointer;
	font-size: 18px;
}


.scrollToTop {
	display: inline;
	position: fixed;
	top: 90%;
	right: 2%;
}

.recommendations {
	margin-top: 2rem;
}

.recommendations h3 {
	margin-left: 1rem;
	margin-bottom: 1.5rem;
}

.howWorldWorks {
	margin: 1rem 0;
	border-bottom: 1px solid #8b8e915d;
	padding-top: 20px;
	padding-bottom: 40px;
}

.howWorldWorksContent span {
	margin-right: 10px;
}

.howWorldWorksContent p {
	font-size: 18px;
}

.howWorldWorksImage {
	width: 80%;
	position: relative;
	bottom: 50px;
}


@media only screen and (max-width : 1300px) {
	.container {
		padding: 0 calc(4rem + 10px) 2rem;
	}

	.howWorldWorksImage {
		width: 90%;
		bottom: 30px;
	}

}

@media only screen and (max-width : 1000px) {
	.container {
		padding: 0 calc(1rem + 10px) 2rem;
	}
}

@media screen and (max-width: 768px) {
	.container {
		padding: 0 1.5rem;
		overflow: hidden;
	}

	.banner {
		border-radius: 0;
		margin: 0 -1.6rem;
		width: 102vw;
		aspect-ratio: 1.5/1;
	}

	.price {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 100;
		padding: 0.5rem 1rem;
		border-top: 2px solid var(--border);
		background-color: var(--background_primary);
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 90px;
	}

	.price span {
		font-size: 1.5rem;
	}

	.price button {
		font-size: 0.8rem;
		padding: 0.5rem 1rem;
		width: fit-content;
		margin: 0;
	}

	.details {
		flex-wrap: wrap;
	}

	.details li {
		flex: 1 1 auto;
		margin-bottom: 15px;
	}

	.video {
		aspect-ratio: 18/9;
		margin-bottom: 2px;
	}

	.scrollToTop {
		top: 82%
	}

	.howWorldWorksImage {
		width: 80%;
		position: static;
	}
}

@media only screen and (max-width:768px) {
	.clubContent {
		padding-top: 1rem;
		position: static;
		z-index: 0;
		height: 80px;
	}

	.videoIcon {
		top: 41%;
		left: 41%;
	}
}

@media only screen and (max-width : 500px) {

	.howWorldWorks {
		margin: 0.5rem 0;
		padding: 0;
		padding-bottom: 20px;
	}
}

@media only screen and (max-width : 400px) {

	.howWorldWorksImage {
		width: 100%;
	}
}