.mentorContainer {
    max-width: 1000px;
    padding-top: 20px;
    padding-bottom: 50px;
}

.leftSticky{
    position: sticky;
    top: 120px;
}

.imgBox {
    position: relative;
    margin-bottom: 30px;
}

.imgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
}

.imgBox h5 {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 15px;
    text-transform: capitalize;
    background-color: var(--background_primary);
    box-shadow: var(--shadow_primary);
    padding: 10px 20px;
    border-radius: 30px;
    opacity: 0.7;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.shareBtn {
    height: 45px;
    min-width: 140px;
    border-radius: 10px;
    background: #e9e9e9 !important;
    color: #000;
}

.shareBtnInner {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.shareBtnInner img {
    width: 30px;
    height: 30px;
}

.msgBtn {
    height: 45px;
    min-width: 140px;
    border-radius: 10px;
    color: #fff;
}

.intro {
    box-shadow: var(--shadow_primary);
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 40px;
    background: var(--background_primary);
}

.intro h5 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.intro p {
    font-size: 15px;
    font-weight: 400;
}

.tabBox {
    padding: 30px;
    margin-top: 30px;
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    background: var(--background_primary);
}

.noWorlds {
    text-align: center;
    background: var(--background_primary);
}

.tabBoxWorlds {
    padding: 30px;
    margin-top: 30px;
    box-shadow: var(--shadow_primary);
    border-radius: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: var(--background_primary);
}

@media only screen and (max-width:768px){
    .leftSticky{
        position: static;
    }

    .tabBox{
        padding: 15px;
    }

    .tabBoxWorlds{
        justify-content: center;
    }

    .shareBtn, .msgBtn{
        min-width: 160px;
    }
}