.dashboard-cards-wrapper {
    padding: 0 28px;
}

.nav-item button {
    font-size: 1rem !important;
}

.snippet-image-past-recording img {
    width: 100%
}

/* slide from top */
.slide-in-from-top {
    animation: slideInFromTop 0.6s ease-in-out;
}

.google-login-suggestion {
    z-index: 999;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 99;
    pointer-events: none;
    right: 20px;
    animation: slideInFromTop 1s ease;
}

.google-login-suggestion-inner {
    padding: 30px;
    background: var(--background_primary);
    border-radius: 5px;
    pointer-events: all;
    max-width: 395px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px var(--fontColor-secondary);
}

.profile-interests {
    padding: 1.5rem;
    border-radius: 30px;
}

.profile-interests h6 {
    margin-bottom: 20px;
    padding-left: 5px;
}

.profile-interest {
    background: var(--background_primary);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    box-shadow: var(--shadow_primary);
}

.mentor-modal-content-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 50px;
}

.mentor-image {
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.swal-ops {
    max-width: 430px;
    padding-bottom: 30px;
    padding-top: 10px;
    border-radius: 1.5rem;
}

#exit-world {
    background: transparent !important;
    color: red;
    border: 2px solid red;
    transition: all 0.3s ease-in-out;
    letter-spacing: 1px;
    font-weight: 700;
}

#exit-world:hover {
    background: red !important;
    color: #fff;
    border: 2px solid red;
}

.accordion-wrapper {
    display: grid;
    place-items: center;
}

.accordion {
    border: 0;
    max-width: 900px;
}

.accordion-item {
    border: 0;
    margin-bottom: 0;
    background-color: var(--background_primary);
    color: var(--fontColor-primary);
    box-shadow: var(--shadow_primary);
    margin: 15px;
    border-radius: 30px;
    min-width: 900px;
}

.accordion-item:first-of-type {
    border-radius: 30px;
}

.accordion-item:last-of-type {
    border-radius: 30px;
}

.accordion-header {
    color: var(--fontColor-primary);
}

.accordion-button {
    background: transparent !important;
    text-transform: unset;
    box-shadow: none;
    font-size: 18px;
}

.accordion-button:not(.collapsed), .accordion-button.collapsed {
    color: var(--fontColor-primary);
    box-shadow: none;
}

.accordion-button:not(.collapsed)::after, .accordion-button::after {
    display: none;
}

.accordion-body {
    padding-top: 0;
    font-size: 17px;
}

.grid-3-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
}

/* .infinite-scroll-component {
    overflow:visible !important;
} */

.str-chat.messaging, .str-chat.commerce, .str-chat__container {
    background: var(--background_primary) !important;
}

.messaging.str-chat .str-chat__header-livestream {
    box-shadow: none !important;
    box-shadow: var(--shadow_primary) !important;
}

.str-chat-channel.messaging .str-chat__main-panel {
    padding: 0 !important;
}

.str-chat__message-text-inner, .str-chat__message-simple-text-inner {
    border: none !important;
    background: var(--grey-whisper) !important;
}

.str-chat__main-panel button, .str-chat__small-message-input-emojiselect {
    background: transparent;
    border: none;
    cursor: pointer;
}

.str-chat__input-flat-emojiselect {
    top: calc(100% - 48px);
}

.str-chat__input-flat .rfu-file-upload-button {
    top: calc(100% - 45px);
}

.str-chat__message .str-chat__reaction-list li button, .str-chat__message-commerce .str-chat__reaction-list li button {
    background: transparent;
    display: flex;
    align-items: center;
}

.str-chat__message-actions-list>button {
    display: none !important;
}

.str-chat__message-actions-list>button:nth-child(3) {
    display: block !important;
}

.rfu-file-upload-button {
    display: none !important;
}

.str-chat__thread-list .str-chat__message-simple__actions {
    display: none !important;
}

.str-chat__thread-header, .messaging.str-chat .str-chat__thread-heade, .str-chat__message-actions-box--open {
    box-shadow: var(--shadow_primary) !important;
}

.str-chat__square-button {
    border: none !important;
}

.str-chat__reaction-selector {
    background-image: none !important;
    box-shadow: var(--shadow_primary) !important;
    background-color: #fff !important;
    color: #000 !important;
}

.str-chat__message-reactions-list-item__count {
    color: #000 !important;
}

.str-chat__textarea textarea {
    border: none;
}

.str-chat__small-message-input-emojiselect {
    bottom: 12px;
    left: 8px;
}

.str-chat__small-message-input textarea {
    padding-left: 30px;
}

.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.video-bg-content {
    position: relative;
    z-index: 1;
}

.dropdown-menu.show {
    background: var(--background_secondary) !important;
}

.dropdown-item  {
    color: var(--fontColor-primary) !important;
}

.rbt-aux .rbt-close {
    border: none !important;
}

.rbt-aux .rbt-close-content {
    font-size: 30px;
    position: relative;
    bottom: 15px;
    display: block;
    color: var(--fontColor-primary);
}

.rbt-token, .rbt-token-removeable {
    color: var(--fontColor-primary);
    background: var(--background_primary);
    border-radius: 10px;
}

.rbt-token-remove-button {
    border: none !important;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@media (max-width: 1000px) {
    .accordion-item {
        min-width: auto;
    }

    .profile-interests {
        margin: 1rem 0 !important;
    }
}

@media (max-width: 700px) {
    .str-chat {
        height: 92vh;
    }

    .str-chat__list .str-chat__reverse-infinite-scroll {
        padding-top: 0 !important;
    }

    .search-bar {
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .share-div {
        margin-top: 0;
    }

    .accordion-button, .accordion-body {
        font-size: 16px;
    }
}